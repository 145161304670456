<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10031')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container">
		<div id="sub" class="sub faq">
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="menus">
						<div class="menus__wrap">
							<!-- [D] 활성화된 지역에 active 클래스 추가 -->
							<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
							<div class="menus__box">
								<div 
								class="menus__text" 
									:class="{'active': selectType === item.TYPE}" 
									v-for="item in faqType" 
									:key="item.TYPE"
									@click="onClickFaqType(item.TYPE)"
								>
									{{item.TYPE_NM}}
								</div>
							</div>
						</div>
					</div>
					<!-- toggle list -->
					<ul class="accordion">
						<li v-for="(item, index) in faqData" :key="index">
							<a class="accordion__question" :class="{'active' : showFaqList.includes(index)}" @click="onClickShowFaq(index)">
								<div class="accordion__info" style="max-width: calc(100% - 26px);">
									<span class="accordion__title ellipsis">
										{{item.QUES_DESC}}
									</span>
								</div>
								<div class="accordion__dropdown">
									<i class="icon icon-down"></i>
								</div>
							</a>
							<div class="accordion__answer" style="white-space: pre-wrap;">
								{{item.ANS_DESC}}
							</div>
						</li>
					</ul>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup() {
		const router = useRouter();
		const store = useStore();

		const selectType = ref("");
		const showFaqList = ref([]);

		const mcs0102 = computed(() => store.state.mcs01.mcs0102);

		const faqType = computed(() => mcs0102.value["01"]);
		const faqData = computed(() => mcs0102.value["02"][selectType.value]);
		const { t }= useI18n() //번역필수 모듈


		onMounted(()=>{
			store.dispatch("mcs01/fetchMcs0102",{
				proc_cd: "01"
			}).then((data)=>{
				if(data.data.Res_data.length > 0){
					selectType.value = data.data.Res_data[0].TYPE;
					
					store.dispatch("mcs01/fetchMcs0102",{
						proc_cd: "02",
						type: data.data.Res_data[0].TYPE
					});
				}
			});
		});

		const onClickFaqType = (type) => {
			selectType.value = type;
			showFaqList.value = [];
			if(mcs0102.value["02"] === undefined || !(type in mcs0102.value["02"])){
				store.dispatch("mcs01/fetchMcs0102",{
					proc_cd: "02",
					type: type
				});
			}
		}

		const onClickShowFaq = (index) => {
			//console.log(index);
			if(showFaqList.value.includes(index)) {
				showFaqList.value = showFaqList.value.filter(item => item !== index);
			}
			else {
				showFaqList.value.push(index);
			}
		}

		const goBack = () => {
			router.back();
		}

		return {
			goBack,
			faqType,
			dayjs,
			i18n,
			selectType,
			onClickFaqType,
			faqData,
			onClickShowFaq,
			showFaqList,
			t
		}
	},
}
</script>